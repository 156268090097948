import React from "react";
import Layout from "../../components/Layout";
import { FaAngleRight } from "@react-icons/all-files/fa/FaAngleRight";

import { Link, graphql } from "gatsby";

const ComponentName = ({ data }) => {
  const daa = data.allStrapiProducts.nodes;
  console.log(daa);
  return (
    <Layout title={"Pest control Products"}> 
      <div className="services">
        <div className="hero">
          <h1>Service Title</h1>
        </div>

        <div className="ssbody wrapper_max">
          <div className="navigation">
            <h3>Our Products</h3>

            <div className="list">
              {daa.map((v, k) => (
                <Link to={v.slug}>
                  <div key={k}>
                    <p>{v.title}</p>
                    <FaAngleRight />
                  </div>
                </Link>
              ))}
            </div>
          </div>

          <div className="content">
            <h2>Title</h2>
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quas
              quos repellat omnis, voluptatibus nihil sunt! Eos illo sequi
              maxime saepe ducimus quidem cupiditate deleniti, distinctio
              voluptatum non beatae fuga ut.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    allStrapiProducts {
      nodes {
        slug
        title
      }
    }
  }
`;

export default ComponentName;
